import { getToken } from "@/auth/config/keycloak"
import { useKeycloak } from "@/auth/provider/KeycloakProvider"
import { useSocket } from "@/auth/provider/SocketContext"

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { Button } from "@/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { useMutation } from "@tanstack/react-query";

export function UserNav() {
  const { logout, user }: any = useKeycloak()
  const { disconnectSocket } = useSocket();

  const token = getToken();

  // Define the mutation for the support logout API
  const { mutateAsync: supportLogout, isPending: isSupportLogoutLoading } = useMutation({
    mutationFn: async () => {
      const response = await fetch(`${process.env.NEXT_PUBLIC_API_SUPPORT_URL}/support/logout`, {
        method: "POST",
        cache: "no-store",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId: user?.userId }),
      });

      if (!response.ok) throw new Error("Failed to call support logout API");
    },
    onSuccess: () => {
      console.log("Support logout successful")
      logout(); // Step 3: Keycloak logout
    },
    onError: (error) => console.error("Error during support logout:", error),
  });


  const handleLogout = async () => {
    disconnectSocket(); // Step 1: Disconnect the socket
    try {
      await supportLogout(); // Step 2: Support logout
    } catch (error) {
      console.error("Error during logout sequence:", error);
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="relative h-8 w-8 rounded-full">
          <Avatar className="h-9 w-9">
            <AvatarImage src="/User5.png" alt="@shadcn" />
            <AvatarFallback>{user?.name?.length > 0 ? user.name.charAt(0) : "AD"}</AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end" forceMount>
        <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">{user?.name}</p>
            <p className="text-xs truncate leading-none text-muted-foreground">
              {user?.email}
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem>Profile</DropdownMenuItem>

          <DropdownMenuItem>Settings</DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={handleLogout}>{isSupportLogoutLoading ? "Logging out..." : "Logout"}</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
